<template>
  <div class='container'>
    <kcHeader></kcHeader>
    <div class="content">
        <div class="kc-aside">
<!--          操作栏-->
          <div class="kc-navBox">
            <router-link to="/myFiles">
              <div :class="index == 2 ?'kc-nav changeKc-nav':'kc-nav'" @click="toMyfiles">
                <img class="img1" :src="index == 2 ? myFiles2:myFiles1" alt="">
                <span >我的云盘</span>
              </div>
            </router-link>
            <router-link to="/transferRecord">
              <div :class="index ==1 ? 'kc-nav changeKc-nav':'kc-nav'" @click="index = 1">
                <img class="img2" :src="index == 1 ? transmission2:transmission1" alt="">
                <span>传输记录</span>
              </div>
            </router-link>
            <router-link to="/recycle">
              <div :class="index ==3 ? 'kc-nav changeKc-nav':'kc-nav'"    @click="index = 3">
                <img class="img3" :src="index == 3 ? recycle2:recycle1" alt="">
                <span>回收站</span>
              </div>
            </router-link>
          </div>
<!--          明细-->
          <div class="kc-DataInfoBox">
<!--            流量-->
            <div style="border-bottom: 1px solid #D8D8D8;" class="kc-DataInfoBox-item">
              <div class="titleBox">
                <div class="left">
                  <img src="@assets/img/profile/icon-uCenterflow.png" alt="">
                <span class="title">流量使用</span>
                </div>
                <span  @click="toShop(3)" class="font" style="background: #55CB5E">充值</span>
              </div>
              <el-progress :format="speedProgress" class="flow"   :stroke-width="10" :percentage="userInfo.flow_prop" ></el-progress>
              <div class="InfoBox">
                <router-link to="/flowDetail">
                <span style="cursor: pointer" >{{  formatSize(userInfo.flow_total - userInfo.flow_over_total)  }}/{{  formatSize(userInfo.flow_total) }}</span>
                  <img src="@assets/img/profile/icon-flowInfo.png" alt="">
                </router-link>
              </div>
            </div>
<!--            空间-->
            <div class="kc-DataInfoBox-item" style="padding-bottom: 0">
              <div class="titleBox">
                <div class="left">
                  <img src="@assets/img/profile/icon-uCenterspace.png" alt="">

                  <span class="title">空间使用</span>

                </div>
                <span @click="toShop(2)" class="font" style="background: #FFC861">扩容</span>
              </div>
              <el-progress :format="speedProgress" class="space"   :stroke-width="10" :percentage="userInfo.storage_prop" ></el-progress>
              <div class="InfoBox">
                <span>{{formatSize(userInfo.storage_total - userInfo.storage_over_total)}}/{{formatSize(userInfo.storage_total)}}</span>

              </div>
            </div>
          </div>
<!--          会员信息-->
<!--          <div class="kc-UserInfoBox">-->
<!--            <div class="kc-level">-->
<!--              <img  :class="userInfo.vip_status == 1?'kc-avatar':'kc-avatar1'" :src="userInfo.avatar" alt="">-->
<!--              <img v-if="userInfo.vip_status == 1" :src="getShopUserLevelImg(userInfo,time)"  class="kc-vip"  alt="">-->
<!--            </div>-->
<!--            <div class="nameBox" >-->
<!--              <span class="name">{{userInfo.username}}</span>-->
<!--              <span @mousemove="getMemberVipStatus" @mouseout="showMemberPopup = false"  @click="$router.push('/shop')" :style="{color:userInfo.vip_status == 0 ?'#0080FF':'#333333'}" class="member">{{userInfo.vip_status == 0?'开通点亮VIP':'有效期：' + userInfo.vip_expiry_time}}</span>-->
<!--            </div>-->
<!--            <div v-show="showMemberPopup"  @mousemove="showMemberPopup = true"  @mouseout="showMemberPopup = false" class="memberPopup">-->
<!--              <div class="top">-->
<!--                <span>当前：{{userInfo.vip_level_show}}</span>-->
<!--                <span>有效期：{{userInfo.vip_expiry_time}}</span>-->
<!--              </div>-->
<!--              <div class="bottom">-->
<!--                <span @click="$router.push('/shop')">续费VIP</span>-->
<!--              </div>-->
<!--              <div class="cone"></div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="kc-contain">
          <router-view  ></router-view>
        </div>

    </div>

  </div>
</template>

<script>
import kcHeader from '@components/kcHeader'
import {getUserInfo} from "@assets/api/user/user";
import {mapMutations, mapState} from "vuex";
import {getGoodsList} from "@assets/api/shop";
export default {
  name:'',
  components: {
    kcHeader
  },

  data () {
    return {
      index:0,
      time:'',
      //图标
      transmission1:require('@assets/img/profile/icon-transmission1.png'),
      transmission2:require('@assets/img/profile/icon-transmission2.png'),
      myFiles1:require('@assets/img/profile/icon-myFiles1.png'),
      myFiles2:require('@assets/img/profile/icon-myFiles2.png'),
      recycle1:require('@assets/img/profile/icon-recycle1.png'),
      recycle2:require('@assets/img/profile/icon-recycle2.png'),
      percentage:0,
      showMemberPopup:false,//会员状态详情弹窗
    };
  },
  computed: {
    ...mapState({userInfo:'userInfo'})
  },


  watch:{
    $route:{
      handler(to){
        if(to.path == '/myFiles'){
          this.index = 2
        }else if(to.path == '/transferRecord'){
          this.index = 1
        }else if(to.path == '/recycle'){
          this.index = 3
        }else{
          this.index = 0
        }
      },
      deep:true,
      immediate:true,
    }

  },
  methods: {
    //获取当前时间
    getNowDate () {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.time = year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
      // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    speedProgress(percentage){
      console.log('percentage',percentage)
      this.percentage = percentage

    },
    //判断会员状态
    getMemberVipStatus(){
      if(this.userInfo.vip_status == 1){
        this.showMemberPopup = true
      }
    },
    //跳转我的云盘
    toMyfiles(){
      this.$store.state.myFilesRandom = Math.random()
      this.index = 2
    },
    //跳转商城
    toShop(index){
      this.$store.state.shopType = index
      this.$router.push({
        path:'/shop',
        name:'Shop',
        params:{
          index
        }
      })
    }

  },

}
</script>
<style lang='less' scoped>

.container{
  //position: fixed;
  width: 100%;
  height: calc(100% - 70px);
}
/deep/.el-progress-bar{
  width: 100%;
  padding: 0;
}
.flow{
  /deep/.el-progress-bar__inner{
    background: linear-gradient(180deg, #7CF07E 0%, #4FC65A 100%);
    box-shadow: 0px 1px 1px 0px rgba(87,206,106,0.46);
    border-radius: 5px;
  }
}
.space{
  /deep/.el-progress-bar__inner{
    background: linear-gradient(180deg, #FFD080 0%, #FFC14A 100%);
    box-shadow: 0px 1px 1px 0px rgba(206,165,87,0.46);
    border-radius: 5px;
  }
}

.content{
  //min-width: 1200px;
  //min-height: calc(100vh - 70px);
  background: #F4F6F8;
  height: 100%;
  display: flex;
  //padding: 20px 30px 20px 20px;
  .kc-aside{
    flex-shrink: 0;
    //margin-right: 25px;
    width: 264px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    background: white;
    //padding-top: 34px;
    padding: 34px  0 20px;
    box-sizing: border-box;
    box-shadow:0px 0px 10px 0px rgba(172,186,209,0.46) ;
    border-top: 1px solid #F4F6F8;
    margin-top: 3px;

    .kc-navBox{
      width: 100%;
      height: auto;
      .kc-nav{
        width: 100%;
        height: 54px;
        background: white;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        //text-align: center;
        //line-height: 54px;
        //position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        //.img1{
        //  width: 25px;
        //  height: 20px;
        //}
        //.img2{
        //  width: 22px;
        //  height: 20px;
        //}
        //.img3{
        //  width: 21px;
        //  height: 22px;
        //}
        img{
          width: 30px;
          height: 30px;
          //position: absolute;
          //top: 50%;
          //transform: translateY(-47%);
          //left: 44px;
          margin-left: 39px;
        }
        span{
          width: 50%;
          margin-left: 20px;
        }

      }
      .changeKc-nav{
        background: linear-gradient(143deg, #23DFB6 0%, #07A9ED 100%) !important;
        box-shadow: 0px 2px 10px -5px rgba(28,122,157,0.59) !important;
        color: #FFFFFF !important;
        opacity: 1 !important;
      }
      .kc-nav:hover{
        width: 100%;
        height: 54px;
        //background: linear-gradient(143deg, #23DFB6 0%, #07A9ED 100%);
        box-shadow: 0px 2px 10px -5px rgba(28,122,157,0.59);
        color: #999999;
        background: #E6FBFF ;
        opacity: 0.59;
      }
    }
    .kc-DataInfoBox{
      width: 100%;
      //height: 150px;
      padding: 0 18px;
      //border-top: 1px solid #E5E5E5;;
      //border-bottom: 1px solid #D8D8D8;
      .kc-DataInfoBox-item{
        width: 100%;
        //height: 100px;
        //margin-top: 41px;
        padding: 20px 0;
        .titleBox{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left{
            display: flex;
            align-items: center;
            img{
              width: 18px;
              height: 18px;
              margin-right: 6px;
              object-fit: contain;
            }
            .title{
              font-size: 16px;
              font-family: PingFang-SC-Regular;
              font-weight: 400;
              color: #666666;
            }
          }

          font-size: 12px;
          font-family: PingFang-SC-Regular;
          font-weight: 400;
          color: #333333;
          margin-bottom: 10px;
          .title{
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .font{
            width: 47px;
            height: 22px;
            font-size: 14px;
            font-family: AliMedium;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
            text-align: center;
            line-height: 22px;
            border-radius: 4px;
            opacity: 0.8;
          }
        }
        .InfoBox{
          margin-top: 10px;
          font-size: 12px;
          font-family:PingFang-SC-Regular;
          font-weight: 400;
          color: #333333;
          display: flex;
          //justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .font{
            font-size: 13px;
            font-family: PingFang-SC-Bold, PingFang SC;
            font-weight: 400;
            cursor: pointer;
          }
          a{
            display: flex;
            align-items: center;
            color: #333333;
            img{
              width: 12px;
              height: 12px;
            }
          }


        }
      }
    }

  }
  .kc-contain{
    flex: 1;
    //padding: 25px 25px  0;
    box-sizing: border-box;
    height: 100%;
  }
}
</style>